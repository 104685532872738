import React from 'react'
import { Link } from "gatsby"

import logo from "../images/rokit-media-logo.svg"
import comicGreenBG from "../images/comic-green-background-small.svg"
import SEO from '../components/SEO'
import { IoRocketOutline } from "react-icons/io5"

const links = () => {
    return (
        <>
            <SEO />
            <section className="container mx-auto w-full h-full px-4 pt-6 pb-16">
                <div className="flex flex-col items-center">
                    <Link to="/" aria-label="Go to Home Page">
                        <img width="200px" height="96px" className=" max-h-20 p-0" src={logo} alt="Modern Website Design Agency" title="Rokit Media Website Design Agency" />
                    </Link>
                </div>
                <div className="flex flex-col items-center">
                    <div className="max-w-xl">
                        <h1 className="text-3xl sm:text-4xl md:text-5xl text-center py-3 font-mono">Hi there, welcome to Rokit Media</h1>
                        <p className="text-2xl sm:text-3xl text-center py-3">Check out our links...</p>
                    </div>
                </div>
                <div className="comic flex flex-col items-center">
                    <div
                        className="panel max-w-xl"
                        style={{
                            backgroundImage: "url(" + comicGreenBG + ")",
                            backgroundSize: "cover",
                            overflow: "hidden",
                        }}>
                        <div>
                            <div className="flex flex-col items-center justify-center px-6 my-12">
                                <a href="https://chatting.page/5hncz74w0f1rqjtnhvzowvisu7kh1cfe" target="_blank" rel="noreferrer">
                                    <h2 className="pulse heading-box-text3 text-3xl sm:text-3xl md:text-4xl lg:text-5xl pb-4"><span>Chat to us Live!</span></h2>
                                </a>
                                <Link to="/">
                                    <h2 className="pulse heading-box-text1 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap py-4"><span>Visit our website</span></h2>
                                </Link>
                                <a href="https://www.facebook.com/Rokit-Media-101543631890296/" target="_blank" rel="noreferrer">
                                    <h2 className="pulse heading-box-text2 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap py-4"><span>Facebook</span></h2>
                                </a>
                                <a href="https://twitter.com/@rokit_media" target="_blank" rel="noreferrer">
                                    <h2 className="pulse heading-box-text3 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap py-4"><span>Twitter</span></h2>
                                </a>
                                <a href="https://www.instagram.com/rokit_media/" target="_blank" rel="noreferrer">
                                    <h2 className="pulse heading-box-text1 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap py-4"><span>Instagram</span></h2>
                                </a>
                                <a href="https://www.linkedin.com/company/rokitmedia/" target="_blank" rel="noreferrer">
                                    <h2 className="pulse heading-box-text2 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap py-4"><span>LinkedIn</span></h2>
                                </a>
                                <Link to="/contact-rokit-media/">
                                    <h2 className="pulse heading-box-text3 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap pt-4"><span>Contact Us</span></h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center pb-8">
              <span className="z-20 inline-flex items-center">&#169; Rokit Media Ltd {(new Date().getFullYear())} <IoRocketOutline className="m-1" /></span>
          </div>
            </section>
        </>
    )
}

export default links